<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <van-collapse-item :title="$t('customer.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('customer.客户编号') }}</h5>
          <p>{{ this.ruleForm.customerCode }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.客户名称') }}</h5>
          <p>{{ this.ruleForm.customerName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.客户英文名称') }}</h5>
          <p>{{ this.ruleForm.customerUsName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.客户类型') }}</h5>
          <p>{{ this.ruleForm.customerType }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.是否为供应商') }}</h5>
          <p>{{ this.ruleForm.isSupplier }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.行业') }}</h5>
          <p>{{ this.ruleForm.industry }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.行业细分') }}</h5>
          <p>{{ this.ruleForm.industryDetail }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.联系人') }}</h5>
          <p>{{ this.ruleForm.contact }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.电话') }}</h5>
          <p>{{ this.ruleForm.telephone }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.邮箱') }}</h5>
          <p>{{ this.ruleForm.email }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.所在地区') }}</h5>
          <p>{{ this.ruleForm.tel }} {{ this.ruleForm.prov }} {{ this.ruleForm.city }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.详细地址') }}</h5>
          <p>{{ this.ruleForm.address }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.是否失效') }}</h5>
          <p>{{ this.ruleForm.isInvalid }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.失效时间') }}</h5>
          <p>{{ this.ruleForm.invalidDate }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('customer.客户开票')" name="2">
        <div class="items">
          <h5>{{ $t('customer.开票名称') }}</h5>
          <p>{{ this.ruleForm.customerName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.是否一般纳税人') }}</h5>
          <p>{{ this.ruleForm.isTaxpayer }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.纳税人识别号') }}</h5>
          <p>{{ this.ruleForm.taxpayerIdentificationNumber }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.电话') }}</h5>
          <p>{{ this.ruleForm.customerBillingPhone }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.开户行') }}</h5>
          <p>{{ this.ruleForm.customerBankOfDeposit }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.账号') }}</h5>
          <p>{{ this.ruleForm.customerBillingAccount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.地址') }}</h5>
          <p>{{ this.ruleForm.isTaxpayer }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('customer.系统信息')" name="4">
        <div class="items">
          <h5>{{ $t('customer.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
  import { Dialog } from 'vant';
  import util from '../../../libs/util';
  import myHistoryPanel from '@/views/business/components/myHistoryPanel';
  import myApproval from '@/views/business/components/myApproval';

  export default {
    name: 'visitorFieldComponent',
    components: {
      myHistoryPanel,
      myApproval
    },
    data() {
      return {
        loading: false,
        finished: false,
        activeNames: ['1', '2', '3', '4', '5'],
        hostName: '',
        task: '',
        personId: '',
        approvalShow: false,
        customSubmitBtnName: ['同意', '不同意'],
        id: '',
        key: '',
        title: '',
        detail: null,
        active: 0,
        businessKey: '',
        mappingId: '',
        taskInstanceId: '',
        currentNode: '',
        variables: {},
        taskType: '',
        ruleForm: {
          id: '',
          customerType: '',
          customerCode: '',
          customerName: '',
          isRename: '',
          telephone: '',
          contact: '',
          email: '',
          address: '',
          operationType: '',
          internalRelatedParties: 'Y',
          isInvalid: 'N',
          isSupplier: 'N',
          invalidDate: '',
          tel: '中国',
          prov: '省份',
          city: '城市',
          editStatus: 'PREPARING',
          purArea: '',
          purAreaCity: '',
          processInstanceId: '',
          supId: '',
          isTaxpayer: 'N',
          taxpayerIdentificationNumber: '',
          customerBillingAddress: '',
          customerBillingPhone: '',
          customerBankOfDeposit: '',
          customerBillingAccount: '',
          suggestedSalesQuota: '',
          creditProposal: '',
          salesCreditLine: '',
          sellOnCreditLimit: '',
          mortgageType: '',
          mortgageAmount: '',
          mortgageDate: '',
          isOverdue: 'N',
          orderStatus: '',
          industry: '',
          industryDetail: '',
          customerUsName: ''
        },
        woCode: 'customerHeader',
        processInstanceName: '客户信息'
      };
    },
    props: {},
    methods: {
      getProcessMappingId() {
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.$myHttp({
          method: 'post',
          url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
          data: {
            entity: {
              woCode: this.woCode,
              orgId: userInfo.companyId,
              deptId: userInfo.deptId
            }
          }
        }).then(res => {
          if (res.data.ext.code === '2000') {
            this.mappingId = res.data.ext.mappingId;
          } else {
            Toast.fail({
              message: res.data.ext.message,
              type: 'error',
              duration: 1500
            });
          }
        });
      },
      backfilleditData(id) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/customer/customerHeader/view',
          data: {
            entity: {
              id: id
            }
          }
        }).then(res => {
          // 成功回调方法
          var data = res.data.entity;
          this.ruleForm = data;
          this.ruleForm.customerType = util.setDict(this.ruleForm.customerType, 'CUSTOMER_TYPE');
          this.ruleForm.industry = util.setDict(this.ruleForm.industry, 'HANGYE');
          this.ruleForm.industryDetail = util.setDict(this.ruleForm.industryDetail, 'HANGYE_MINGXI');
          this.ruleForm.isTaxpayer = util.setDict(this.ruleForm.isTaxpayer, 'PUB_Y_N');
          if (this.ruleForm.isSupplier === 'Y') {
            this.ruleForm.isSupplier = '是'
          } else {
            this.ruleForm.isSupplier = '否'
          }
          if (this.ruleForm.isInvalid === 'Y') {
            this.ruleForm.isInvalid = '是'
          } else {
            this.ruleForm.isInvalid = '否'
          }
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        });
      },
      setApprovalData(row) {
        if (row && row.pid) {
          this.ruleForm.id = row.businessKey;
          this.taskInstanceId = row.id;
          this.taskType = row.taskType;
        }
      },
      /**
       * 业务提交之前校验
       * */
      checkBeforeProcess(type) {
        if (type) {
          if (type === 'beforeAddsign') {
            this.$refs.demoTemperatureEdit.beforeAddsign();
          } else if (type === 'afterAddsign') {
            this.$refs.demoTemperatureEdit.afterAddsign();
          } else if (type === 'endProcess') {
            this.$refs.demoTemperatureEdit.endProcess();
          } else if (type === 'repelProcess') {
            this.repelProcess();
          } else {
            if (!this.taskInstanceId) {
              var params = {
                businessKey: this.ruleForm.id,
                mappingId: this.mappingId,
                processInstanceName: this.processInstanceName,
                userName: sessionStorage.getItem('userName'),
                variables: this.variables
              };
              this.startProcessAction(params);
            } else {
              this.$refs.demoTemperatureEdit.submit();
            }
          }
        } else {
          Toast.fail({
            message: this.$t('customer.数据校验失败'),
            type: 'warning'
          });
        }
      },
      // 业务发起流程
      startProcessAction(params) {
        if (params.mappingId) {
          params.mappingId = this.mappingId;
        }
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.customerCode, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/customer/customerHeader/start',
          data: params
        })
          .then(res => {
            var backData = res.data.ext;
            if (backData) {
              this.$refs.demoTemperatureEdit.submitLoading = false;
              this.$refs.demoTemperatureEdit.isStart = true;
              this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
              this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
              this.$refs.demoTemperatureEdit.isFirstNode = true;
              this.$refs.demoTemperatureEdit.taskId = backData.taskId;
              this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
              this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
            } else {
              Toast.fail({
                message: this.$t('customer.流程发起异常'),
                type: 'warning'
              });
              this.$refs.demoTemperatureEdit.submitLoading = false;
            }
          })
          .catch(_ => {
            this.$refs.demoTemperatureEdit.submitLoading = false;
          });
      },
      // 业务提交流程
      submitProcessAction(params) {
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.customerCode, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/customer/customerHeader/submit',
          data: params
        })
          .then(res => {
            if (res.data.ext.success) {
              this.processCallback(null, null, this.taskType);
            } else {
              Toast.fail({
                message: res.data.ext.msg,
                type: 'warning'
              });
            }
          })
          .catch(_ => {});
      },
      /***
       * 业务撤销流程
       */
      repelProcess() {
        var _this = this;
        _this
          .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
            cancelButtonClass: 'sysBackBtn'
          })
          .then(_ => {
            this.$myHttp({
              method: 'post',
              url: '/microarch/customer/customerHeader/undoProcess',
              data: {
                entity: {
                  id: this.ruleForm.id,
                  processInstanceId: this.ruleForm.processInstanceId
                }
              }
            })
              .then(res => {
                if (res.data.success === undefined || res.data.success) {
                  Toast.fail({
                    message: this.$t('customer.撤销成功'),
                    type: 'success'
                  });
                  this.processCallback(null, null, this.taskType);
                } else {
                  Toast.fail({
                    message: res.data.msg,
                    type: 'warning'
                  });
                }
              })
              .catch(_ => {});
          })
          .catch(_ => {});
      },

      /***
       * 关闭发起流程审批窗口
       * 删除流程实例，回滚业务状态
       */
      closeStartCallback(pid) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/customer/customerHeader/deleteProcess',
          data: {
            entity: {
              id: this.ruleForm.id,
              processInstanceId: pid
            }
          }
        })
          .then(res => {
            this.$refs.demoTemperatureEdit.dialogVisible = false;
            this.$refs.demoTemperatureEdit.taskId = '';
            this.$refs.demoTemperatureEdit.processInstanceId = '';
            this.processCallback(null, null, this.taskType);
          })
          .catch(_ => {});
      },

      /**
       * 提交之前校验
       * */
      beforeSubmitCallBack(params) {
        // 业务参数校验 此时可以从params中获取提交审批所有的参数
        // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
        // 不通过给出相应的提示或者触发其他业务操作
        // let selectUserArray = params.candidateUserIds[params.nextId]
        // console.log(selectUserArray);
        this.submitProcessAction(params);
      },
      /**
       * 流程审批之后业务数据更改
       * */
      processCallback(piid, optionType, taskType) {
        var _this = this;
        // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
        // // 成功回调方法
        // if (taskType === 'transated') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/haveList'
        //   });
        // } else if (taskType === 'waitfor') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/toDoList'
        //   });
        // } else {
        //   this.$router.push({
        //     name: 'sale/return/saleReturnApplyList'
        //   });
        //   this.$bus.emit('saleReturnApply-SaveSuccess');
        // }
        this.$router.go(-1);
      }
    },
    created() {},
    mounted() {
      let selectRow = JSON.parse(this.$route.query.row);
      if (selectRow) {
        this.ruleForm.id = selectRow.id;
        if (selectRow.businessKey) {
          this.ruleForm.id = selectRow.businessKey;
        }
      }
      if (this.ruleForm.id) {
        this.backfilleditData(this.ruleForm.id);
      }

      if (selectRow) {
        this.setApprovalData(selectRow);
      }
    },
    filters: {
      setDict(v, dictName) {
        return util.setDict(v, dictName);
      }
    }
  };
</script>

<style lang="less" scoped>
  .top {
    overflow: hidden;

    .left-col {
      float: left;
      width: 60%;
    }

    .img {
      float: right;
    }
  }

  .visitor-details {
    p {
      color: #aab2bd;
    }

    h5 {
      font-weight: bold;
    }
  }

  .img {
    width: 40%;
    text-align: center;
    padding: 10px;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .panel-list {
    .panel {
      margin-top: 5px;
      background: #fff;
    }

    .divline {
      padding: 0 0 5px 0;
    }

    .icon-status {
      width: 50px;
      height: 45px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      bottom: 36px;
      right: 5px;
    }

    .type-0 {
      background-image: url('../../../images/icon-queryStatus-0.png');
    }

    .type-1 {
      background-image: url('../../../images/icon-queryStatus-1.png');
    }

    .type-2 {
      background-image: url('../../../images/icon-queryStatus-2.png');
    }

    .type-3 {
      background-image: url('../../../images/icon-queryStatus-3.png');
    }

    .type-4 {
      background-image: url('../../../images/icon-queryStatus-4.png');
    }
  }

  .table {
    margin-top: 5px;

    td {
      background: #f6f6f6;
      height: auto;
      padding: 8px 5px;
      border-bottom: 5px solid #fff;
    }
  }

  .personal-table {
    td {
      text-align: left;
      word-break: break-all;
    }

    img {
      vertical-align: middle;
    }

    .idcard {
      font-size: 12px;
      padding: 0;
    }
  }

  .car-table {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .visit-record {
    padding: 2px 0 0;

    ul {
      li {
        padding: 3px 0;
      }
    }
  }
</style>
